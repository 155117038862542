function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }
  const script = document.createElement('script');
  //script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

function appendDiv(position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }
  const div_elem = document.createElement('div');
  //script.setAttribute('async', '');
  div_elem.setAttribute('id', id);
  position.appendChild(div_elem);
}

export {loadScript, appendDiv}