import {
  Box,
  Button,
  LinearProgress,
  TextField,
  ThemeProvider,
} from "@mui/material";

import axios from "axios";
import styled from "styled-components";
import svgToMiniDataURI from "mini-svg-data-uri";
import themes from "./themes/theme";
import { useState } from "react";

const Main = styled.main`
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  color: ${(props) => props.theme.colors.textColor};
  font-family: ${(props) => props.theme.typography.fontFamily};
  text-align: center;
`;

const ButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: ${(props) => props.theme.colors.textColor};
  font-family: ${(props) => props.theme.typography.fontFamily};

  .MuiButton-root {
    margin: 16px;
    min-width: 150px;
  }
`;

const SVGContainer = styled(Box)`
  display: flex;
  --dimension: min(400px, 90vw); 
  width: var(--dimension);
  height: var(--dimension);
  color: ${(props) => props.theme.colors.textColor};
  font-family: ${(props) => props.theme.typography.fontFamily};

  img {
    flex: 1;
    width: 100%;
    height: 100%;
  }
`;

const Input = styled(TextField)`
  margin-bottom: 16px;
`;

const ErrorMessage = styled.p`
  color: #ff0033;
`;

function QrPage() {
  const [qrSvg, setQrSvg] = useState<string>();
  const [storeId, setStoreId] = useState<string>();
  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const getQr = () => {
    if (storeId) {
      setLoadingData(true);
      axios
        .get(
          `${process.env.API_HOST}/cep-ms-resolver/v1/_custom/isdc/qr/${storeId}`
        )
        .then((response) => {
          setQrSvg(response.data as string);
          setLoadingData(false);
        })
        .catch((error) => {
          if (error.response) {
            error.response.data.detail ? 
            setErrorMessage(error.response.data.detail) :
            setErrorMessage(error.response.data)
          } else if (error.request) {
            setErrorMessage(JSON.stringify(error.request));
          } else {
            setErrorMessage(error?.message);
          }
          setLoadingData(false);
          setError(true);
        });
    }
  };

  const goBack = () => {
    setQrSvg(undefined);
    setStoreId(undefined);
  };

  const optimizedSVGDataURI = svgToMiniDataURI(qrSvg || "");

  if (!qrSvg && !loadingData) {
    return (
      <ThemeProvider theme={themes.base}>
        <Main>
          <form onSubmit={getQr}>
            <Input
              value={storeId}
              label="Store ID"
              variant="outlined"
              onChange={(e) => setStoreId(e.target.value)}
              inputProps={{ pattern: "[0-9-]{1,6}" }}
            />
            <br />
            {error && <ErrorMessage>Error: {errorMessage}</ErrorMessage>}
            <Button type="submit" variant="contained" color="primary">
              Get QR Code
            </Button>
          </form>
        </Main>
      </ThemeProvider>
    );
  } else {
    return qrSvg && !loadingData ? (
      <ThemeProvider theme={themes.base}>
        <Main>
          <SVGContainer>
            <img src={optimizedSVGDataURI} alt="" />
          </SVGContainer>
          <ButtonContainer className="no-print">
            <Button variant="contained" onClick={window.print} color="primary">
              Print
            </Button>
            <Button variant="contained" onClick={goBack}>
              Go Back
            </Button>
          </ButtonContainer>
        </Main>
      </ThemeProvider>
    ) : (
      <LinearProgress color="inherit" />
    );
  }
}

export default QrPage;
