export const utilityGenderFormat = (gender: string) => {
  let genderFormatted;
  switch (gender) {
    case "f":
      genderFormatted = "female";
      return genderFormatted;
    case "m":
      genderFormatted = "male";
      return genderFormatted;
    case "o":
      genderFormatted = "other";
      return genderFormatted;
    default:
      genderFormatted = "other";
  }
  return genderFormatted;
};
