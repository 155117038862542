import styled from "styled-components";
import imgCourtesyPage from "../assets/courtesyPage.svg";

const ImageCourtesyPage = styled.img`
  width: 40vw;
  @media (max-width: 768px) {
    width: 80vw;
  }
`;

const ButtonReload = styled.button`
  color: white;
  border: 1px solid white;
  background-color: transparent;
  cursor: pointer;
  border-radius: 40px;
  width: 35vw;
  height: 5vh;
  font-size: 25px;
  margin-top: 5vw;
  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const ContainerCourtesyPage = styled.div`
  background-color: black;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const CourtesyPage = () => {
  return (
    <ContainerCourtesyPage>
      <ImageCourtesyPage src={imgCourtesyPage} />
      <ButtonReload onClick={() => window.location.reload()}>
        Reload
      </ButtonReload>
    </ContainerCourtesyPage>
  );
};
