import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

interface Props {
  dialogContent: string;
  isOpen: boolean;
  children: JSX.Element;
}

export default function AlertDialog({
  dialogContent,
  isOpen,
  children,
}: Props) {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth={true}
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>{children}</DialogActions>
    </Dialog>
  );
}
