import axios from "axios";

interface ValidateEmailResponse {
  operation_id: string;
  validation_details: string[];
  validation_outcome: "error" | "success" | "warning";
}

export const validateEmail = ({
  subscriptionKey,
  email,
}: {
  subscriptionKey: string;
  email: string;
}) => {
  const getResponse = async () => {
    const res = await axios.post(
      `${process.env.API_HOST}/cep-ms-isdcbff/v1/validate/email`,
      { value: email },
      {
        headers: {
          "Ocp-Apim-Subscription-Key": subscriptionKey,
        },
      }
    );

    return res.data as ValidateEmailResponse;
  };

  return getResponse();
};
