import FormValues from "../types/FormValues";
import axios from "axios";

const emailContact = function (properties: any[]) {
  if (properties === undefined) return undefined;
  const emailProperty: Record<string, string | null> | undefined =
    properties.find((p) => p.propertyCode === "EMAIL_CONTACT");
  return emailProperty?.stringValue === "YES";
};

const transformFormValues = (
  storeId: string,
  partyId: string,
  subscriptionKey: string,
  customerHash?: string
) => {
  const getData = async () => {
    const res = (
      await axios
        .get(`${process.env.API_HOST}/cep-ms-isdcbff/v1/party_details`, {
          params: {
            store_id: storeId,
            party_id: partyId,
            customer_hash: customerHash,
          },
          headers: {
            "Ocp-Apim-Subscription-Key": subscriptionKey,
          },
        })
        .catch((error) => {
          if (error.response) {
            throw new Error(
              "get customer data error: " + JSON.stringify(error.response.data)
            );
          } else if (error.request) {
            throw new Error(
              "get customer data error: " + JSON.stringify(error.request)
            );
          } else {
            throw new Error("get customer data error: " + error?.message);
          }
        })
    ).data as FormValues;

    // const retvalue: FormValues = {
    //   address: res.address1 ?? undefined,
    //   email: res.emailAddress ?? undefined,
    //   first_name: res.firstName ?? "",
    //   last_name: res.lastName ?? "",
    //   birthdate: res.birthday?.substring(0, 10) ?? undefined,
    //   city: res.city ?? undefined,
    //   country: res.country ?? undefined,
    //   gender: res.gender?.toLocaleLowerCase() ?? undefined,
    //   mobile: res.cellPhone ?? undefined,
    //   state: res.state ?? undefined,
    //   zip_code: res.postal ?? undefined,
    //   email_marketing: emailContact(res.partyProperties) ?? undefined,
    //   phone_marketing: res.cellPhoneContact ?? undefined,
    // };

    const retValue = { ...res };

    return retValue;
  };

  return getData();
};

export default transformFormValues;
