export enum FormFields {
  FirstName = "first_name",
  LastName = "last_name",
  Gender = "gender",
  Birthdate = "birthdate",
  Email = "email",
  Address = "address",
  ZipCode = "zip_code",
  City = "city",
  State = "state",
  Country = "country",
  Mobile = "mobile",
  ConsentData = "consent_data",
  Loyalty = "loyalty",
  LoyaltyAccepted = "loyalty_accepted",
  OnlyEmailMarketing = "only_email_marketing",
  OnlyPhoneMarketing = "only_phone_marketing",
  OnlyPostalMarketing = "only_postal_marketing",
  EmailMarketing = "email_marketing",
  PhoneMarketing = "phone_marketing",
  PostalMarketing = "postal_marketing",
  Signature = "signature",
  DisclaimerUS = "disclaimer_US",
  DisclaimerROW = "disclaimer_ROW",
}

export default interface StoreMap {
  banner_code: string;
  image_path: string;
  themeName: string;
  fields: {
    [key: string]: FormFields[];
  };
  externalLinks: {
    privacyPolicy: {
      [key: string]: string;
    };
    termsAndConditions: {
      [key: string]: string;
    };
    publicFormRedirect: {
      [key: string]: string;
    };
    californiaNFI: {
      [key: string]: string;
    };
  };
}
