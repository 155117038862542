import StoreIsdc from "../types/StoreIsdc"

/**
 * @param  {number} country - the country of the store
 * @returns {Date} the earliest acceptable date
 */
function getMaxDateByCountry(country: StoreIsdc['country']): Date {
	const date = new Date()
	const age = country === 'US' ? 16 : 18
	const year = date.getFullYear() - age
	date.setFullYear(year)
	return date
}

export { getMaxDateByCountry }
