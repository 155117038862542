import { FormFields } from "../types/StoreMap";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import TextField from "@mui/material/TextField";
import { getMaxDateByCountry } from "../utils/date";
import { useTranslation } from "react-i18next";
import AdapterMoment from "@mui/lab/AdapterMoment";
import moment, { Moment } from "moment";
import { useContext } from "react";
import { Context } from "../App";

import "moment/locale/de";
import "moment/locale/en-gb";
import "moment/locale/fr-ca";
import "moment/locale/es";
import "moment/locale/fr";
import "moment/locale/tr";
import "moment/locale/pt";
import "moment/locale/nl";
import "moment/locale/it";
moment.locale("en-gb");

interface BirthdatePickerProps {
  disabled: boolean;
  required: boolean;
  country?: string;
  value: string;
  handleChange: (date: Moment | null) => void;
  helperText?: string | false;
  error?: boolean;
  cancelText: string;
  okText: string;
}
//* Sort years in descending order
class DateAdapter extends AdapterMoment {
  getYearRange = (start: Moment, end: Moment) => {
    const startDate = this.moment(end).startOf("year");
    const endDate = this.moment(start).endOf("year");
    const years: Moment[] = [];

    let current = startDate;
    while (current.isAfter(endDate)) {
      years.push(current);
      current = current.clone().subtract(1, "year");
    }

    return years;
  };

  getWeekdays = () => {
    return ["", "", "", "", "", "", ""];
  };
}

const BirthdatePicker = ({
  disabled,
  country,
  value,
  handleChange,
  helperText,
  error,
  cancelText,
  okText,
}: BirthdatePickerProps) => {
  const { storeIsdcData } = useContext(Context);
  const { t } = useTranslation();
  const regionalDateFormat = country === "US" || country === "CA" 
          ? "MM/DD/YYYY"
          : "DD/MM/YYYY";

  return (
    <LocalizationProvider
      dateAdapter={DateAdapter}
      dateLibInstance={moment}
      locale={storeIsdcData.isdc__language?.toLowerCase()}
    >
      <MobileDatePicker
        openTo="year"
        views={["year", "month", "day"]}
        label={t("form.birthdate")}
        value={value ? value : null}
        onChange={(date) => {
          handleChange(date);
        }}
        disabled={disabled}
        maxDate={moment(getMaxDateByCountry(country))}
        inputFormat={regionalDateFormat}
        cancelText={cancelText}
        okText={okText}
        renderInput={(params) => (
          <TextField
            {...params}
            id={FormFields.Birthdate}
            name={FormFields.Birthdate}
            fullWidth
            size="small"
            error={error}
            helperText={helperText}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default BirthdatePicker;
