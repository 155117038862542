import Form, { FieldsConfig } from "./components/Form";
import NoMapsAPIForm from "./components/NoMapsAPIForm";
import WiFiForm from "./components/WiFiForm";
import { version } from "../package.json";
import {
  LinearProgress,
  Theme,
  ThemeProvider,
  Typography,
} from "@mui/material";
import React, { createContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import FormValues from "./types/FormValues";
import StoreIsdc from "./types/StoreIsdc";
import StoreMap from "./types/StoreMap";
import axios from "axios";
import storesMap from "./assets/storesMap.json";
import styled from "styled-components";
import themes from "./themes/theme";
import transformFormValues from "./services/transformFormValues";

import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CourtesyPage } from "./components/CourtesyPage";
import { loadGoogleMapApi } from "./utils/googleAPI";

const Main = styled.main`
  padding: 15px;
  padding-bottom: 100px;
  color: ${(props) => props.theme.colors.textColor};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const Header = styled.header`
  text-align: center;
`;

const Hero = styled.img`
  width: calc(100% + 30px);
  height: auto;
  margin: -15px -15px 0;
`;

const Logo = styled.img`
  max-width: 50%;
  max-height: 40px;
  height: auto;
  margin: 30px 0;
`;

const Title = styled(Typography)`
  max-width: 525px;
  margin: auto;
`;

const FormContainer = styled.div`
  max-width: 420px;
  margin: auto;
  margin-top: 24px;
`;

const Description = styled(Typography)`
  margin-bottom: 36px;
  text-align: center;
`;

const Context = createContext(
  {} as {
    storeIsdcData: StoreIsdc;
    subscriptionKey: string;
    usingPos: boolean;
    storeMapData: StoreMap;
    partyId?: string;
  }
);

function App() {
  const { t, i18n } = useTranslation();
  const loaded = useRef(false);
  const [storeIsdcData, setStoreIsdcData] = useState<StoreIsdc>();
  const [storeMapData, setStoreMapData] = useState<StoreMap>();
  const [goToCourtesyPage, setGoToCourtesyPage] = useState(false);
  const [customTheme, setTheme] = useState<Theme>();
  const [fieldsConfig, setFieldsConfig] = useState<FieldsConfig>();
  const [usingPos, setUsingPos] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<FormValues>();
  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const storeId =
    searchParams.get("source_site") ??
    searchParams.get("sid") ??
    searchParams.get("gsid") ??
    "";
  let subscriptionKey = searchParams.get("subscription_key") ?? "";
  const pos = searchParams.get("pos") ?? "";
  const posType = searchParams.get("pos_type") ?? "";
  const partyId = searchParams.get("cid") ?? undefined;
  const customerHash = searchParams.get("customer_hash") ?? undefined;
  const publicForm = searchParams.get("public_form") ?? "";
  const merakiRedirectUrl = searchParams.get("base_grant_url") ?? undefined;
  const merakiUserContinueUrl = searchParams.get("user_continue_url") ?? undefined;

  useEffect(() => {
    if (!loaded.current) {
      //* Loading the Maps JavaScript API
      loadGoogleMapApi();

      loaded.current = true;
    }
  }, []);

  const resetPage = () => {
    if (publicForm && subscriptionKey === process.env.SUBSCRIPTION_KEY) {
      setSearchParams({
        sid: storeId,
        public_form: publicForm,
      });
    } else if (publicForm && subscriptionKey) {
      setSearchParams({
        sid: storeId,
        public_form: publicForm,
        subscription_key: subscriptionKey,
      });
    } else
      setSearchParams({
        sid: storeId,
        subscription_key: subscriptionKey,
      });
  };

  if (publicForm && !subscriptionKey && process.env.SUBSCRIPTION_KEY)
    subscriptionKey = process.env.SUBSCRIPTION_KEY;

  useEffect(() => {
    axios
      .get(
        `${process.env.API_HOST}/cep-ms-isdcbff/v1/store_details?store_id=${storeId}`,
        {
          headers: {
            "Ocp-Apim-Subscription-Key": subscriptionKey,
          },
        }
      )
      .then((response) => {
        const isdcData = response.data as StoreIsdc;
        const language = isdcData.isdc__language?.toLowerCase();
        const formAUNZ = isdcData.country === "AU" || isdcData.country === "NZ" ;
        const formSEA = isdcData.country === "SG" ; 

        if (language) i18n.changeLanguage(language);
        if (formAUNZ) i18n.changeLanguage('au');
        if (formSEA) i18n.changeLanguage('sg');

        setStoreIsdcData(isdcData);
      })
      .catch((error) => {
        if (error.response) {
          setErrorMessage(
            "store-profiler error: " + JSON.stringify(error.response.data)
          );
        } else if (error.request) {
          setErrorMessage(
            "store-profiler error: " + JSON.stringify(error.request)
          );
        } else {
          setErrorMessage("store-profiler error: " + error?.message);
        }
        //if (error.response.status === 503) setGoToCourtesyPage(true);
        setGoToCourtesyPage(true);
        setError(true);
      });

    if (pos) setUsingPos(true);
    if (posType && posType === "tablet") setUsingPos(true);
    if (posType && posType === "desktop") setUsingPos(false);
    if (publicForm) setUsingPos(false);

    if (partyId) {
      transformFormValues(storeId, partyId, subscriptionKey, customerHash)
        .then((retValue) => {
          setFormValues(retValue);
          setLoadingData(false);
        })
        .catch((error) => {
          setErrorMessage("QR code is not valid. Please close and reopen the app.");
          setError(true);
        });
    } else {
      setUsingPos(false);
      setFormValues(undefined);
      setLoadingData(false);
    }
  }, [i18n, partyId, storeId, subscriptionKey]);

  useEffect(() => {
    if (storeIsdcData?.banner_code) {
      setStoreMapData(
        (storesMap as unknown as StoreMap[]).find(
          (s) => s.banner_code === storeIsdcData?.banner_code
        )
      );
    }
  }, [storeIsdcData]);

  useEffect(() => {
    if (storeMapData) {
      setTheme(themes[storeMapData.themeName]);
    }
  }, [storeMapData]);

  useEffect(() => {
    if (storeIsdcData && storeMapData) {
      const fields =
        storeMapData.fields[storeIsdcData?.country ?? "default"] ??
        storeMapData.fields["default"];
      const fieldsConfig: FieldsConfig = fields.reduce((acc, field) => {
        return { ...acc, [field]: true };
      }, {});

      setFieldsConfig(fieldsConfig);
    }
  }, [storeIsdcData, storeMapData]);

  return subscriptionKey &&
    storeIsdcData &&
    storeMapData &&
    customTheme &&
    !loadingData ? (
    <ThemeProvider theme={customTheme}>
      <Context.Provider
        value={{
          storeIsdcData: storeIsdcData,
          subscriptionKey: subscriptionKey,
          usingPos: usingPos,
          storeMapData: storeMapData,
          partyId: partyId,
        }}
      >
        <Helmet>
          <title>{storeMapData.banner_code}</title>
          <link
            rel="icon"
            href={`/static/images/${storeMapData.image_path}/favicon.ico`}
          />
          <link
            rel="apple-touch-icon"
            href={`/static/images/${storeMapData.image_path}/favicon.ico`}
          />
        </Helmet>
        <Main>
          <Header>
            <Hero
              src={`/static/images/${storeMapData.image_path}/hero.jpg?v=${version}`}
            />
            <Logo
              src={`/static/images/${storeMapData.image_path}/logo.jpg?v=${version}`}
            />
            <Title variant="h1">
              {t(`${storeMapData.image_path}.headline`)}
            </Title>
          </Header>
          {fieldsConfig && !merakiRedirectUrl && (
            <FormContainer>
              <Description variant="body1">
                { !storeIsdcData?.isdc__loyalty_enabled && (t(`${storeMapData.image_path}.body`))}
                { storeIsdcData?.isdc__loyalty_enabled  && (t(`${storeMapData.image_path}.loyaltyBody`, ''))}
              </Description>
              { document.querySelector("#gmaps_apis_compatible") && (
                <Form
                  fieldsConfig={fieldsConfig}
                  formInitialValues={formValues}
                  resetPage={resetPage}
                  disabledFields={!!partyId}
                  publicForm={publicForm}
                  merakiRedirectUrl={merakiRedirectUrl}
                  merakiUserContinueUrl={merakiUserContinueUrl}
                  posType={posType}
                  pos={!!pos}
                />
              )}
              { !document.querySelector("#gmaps_apis_compatible") && (
                <NoMapsAPIForm
                  fieldsConfig={fieldsConfig}
                  formInitialValues={formValues}
                  resetPage={resetPage}
                  disabledFields={!!partyId}
                  publicForm={publicForm}
                  merakiRedirectUrl={merakiRedirectUrl}
                  merakiUserContinueUrl={merakiUserContinueUrl}
                  posType={posType}
                  pos={!!pos}
                />
              )}
            </FormContainer>
          )}
          {fieldsConfig && merakiRedirectUrl &&(
            <FormContainer>
              <Description variant="body1">
                {t(`${storeMapData.image_path}.WiFiBody`, '')}
              </Description>
              <WiFiForm
                fieldsConfig={fieldsConfig}
                formInitialValues={formValues}
                resetPage={resetPage}
                disabledFields={!!partyId}
                publicForm={publicForm}
                merakiRedirectUrl={merakiRedirectUrl}
                merakiUserContinueUrl={merakiUserContinueUrl}
                posType={posType}
                pos={!!pos}
              />
            </FormContainer>
          )}
        </Main>
      </Context.Provider>
    </ThemeProvider>
  ) : error ? (
    goToCourtesyPage ? (
      <CourtesyPage />
    ) : (
      <>
        <p>Error: {errorMessage}</p>
      </>
    )
  ) : (
    <LinearProgress color="inherit" />
  );
}

export default App;
export { Context };
