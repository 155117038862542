import { useEffect, useState } from "react";
import styled from "styled-components";
import type { Option } from "../types/option";

const StyledDropdown = styled("select")`
  border-radius: 5px;
  font-family: inherit;
  color: inherit;
  font-size: 12px;
  width: 100%;
  height: 75%;
  bottom: 5px;
  position: relative;
`;

type DropdownProps = {
  id: string;
  options: Option[];
  value?: string;
  disabled?: boolean;
  className?: string;
  onChange: (value: string) => void;
};

const Dropdown = ({ id, options, onChange, ...props }: DropdownProps) => {
  const defaultValue = "--";
  const [value, setValue] = useState<string>(defaultValue);

  useEffect(() => {
    if (props.value) setValue(props.value);
  }, [props.value]);

  const handleValueChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  return (
    <StyledDropdown
      id={id}
      name={id}
      value={value}
      defaultValue={value}
      disabled={props.disabled}
      className={props.className}
      onChange={(e) => handleValueChange(e.currentTarget.value)}
    >
      <option value={defaultValue}>--</option>
      {options.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
    </StyledDropdown>
  );
};

export default Dropdown;
