import * as React from "react";

import PlacesAutocomplete, { Suggestion } from "react-places-autocomplete";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import { FormFields } from "../types/StoreMap";
import Grid from "@mui/material/Grid";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { createFilterOptions, FilterOptionsState } from "@mui/material";
import { useContext } from "react";
import { Context } from "../App";

interface LocationSearchInputProps {
  suggestion: string | Suggestion | null;
  setSuggestion: React.Dispatch<
    React.SetStateAction<string | Suggestion | null>
  >;
  value: string;
  handleChange: (value: string) => void;
  error?: boolean;
  helperText?: string | false;
  disabled: boolean;
  required: boolean;
  handleSelect: (address: string) => Promise<void>;
  countrySelected: string;
}

const LocationSearchInput = ({
  suggestion,
  setSuggestion,
  value,
  handleChange,
  error,
  helperText,
  disabled,
  required,
  handleSelect,
  countrySelected,
}: LocationSearchInputProps) => {
  const { t } = useTranslation();
  const onChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: string | Suggestion | null
  ) => setSuggestion(value);

  const searchOptions = {
    componentRestrictions: {
      country: `${countrySelected.toLowerCase() ?? "us"}`,
    },
    fields: ["address_components", "geometry"],
    types: ["address"],
  };
  const onError = (status: string, clearSuggestions: () => void) => {
    console.error("Google Maps API returned error with status: ", status);
    clearSuggestions();
  };
  const filter = createFilterOptions<Suggestion>();
  return (
    <PlacesAutocomplete
      value={value}
      onChange={handleChange}
      onSelect={handleSelect}
      onError={onError}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <Autocomplete
          id="google-map"
          getOptionLabel={(option) =>
            typeof option === "string"
              ? option
              : option.formattedSuggestion.mainText
          }
          options={suggestions}
          autoComplete
          includeInputInList
          value={suggestion}
          onChange={onChange}
          inputValue={value}
          onInputChange={(event, value) => handleChange(value)}
          filterOptions={(options, params: FilterOptionsState<Suggestion>) => {
            const filtered = filter(options, params);
            const { inputValue } = params;
            // Suggest the creation of a new value
            const isExisting = options.some(
              (option) => inputValue === option.description
            );
            if (inputValue !== "" && !isExisting) {
              filtered.push({
                id: "add",
                active: true,
                index: 0,
                description: inputValue,
                placeId: "add",
                formattedSuggestion: {
                  mainText: inputValue,
                  secondaryText: inputValue,
                },
                matchedSubstrings: [],
                terms: [],
                types: [],
              });
            }

            return filtered;
          }}
          isOptionEqualToValue={(option, value) =>
            option.placeId === value.placeId
          }
          freeSolo
          disabled={disabled}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...getInputProps()}
              {...params}
              name={FormFields.Address}
              id={FormFields.Address}
              fullWidth
              size="small"
              error={error}
              required={required}
              helperText={helperText}
              label={t("form.address")}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...getSuggestionItemProps(option)} key={option.placeId}>
                <Grid container alignItems="center">
                  <Grid item>
                    <Box
                      component={LocationOnIcon}
                      sx={{ color: option.active ? "red" : "inherit" }}
                    />
                  </Grid>
                  <Grid item xs>
                    <span {...props}>{option.description}</span>
                  </Grid>
                </Grid>
              </li>
            );
          }}
        />
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
