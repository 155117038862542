const loyalty_domain_blacklist = [
	'onesight.org',
	'luxottica.com',
	'barberini.eu',
	'barberini.pe.it',
	'barberinieyewear.it',
	'sunglasshutaccount.co.za',
	'sunglasshut.co.za',
	'onesight.org.za',
	'occhiali.co.za',
	'luxottica.co.za',
	'raybanstore.co.za',
	'ray-banstore.co.za',
	'labservices.luxottica.com',
	'cz.sunglasshut.com',
	'searsoptical.com',
	'eyemedvisioncare.com',
	'eyemed.com',
	'luxotticaretail.com',
	'pearlevision.com',
	'oakley.com',
	'sunglasshut.com',
	'ray-ban.com',
	'salmoiraghievigano.it',
	'nextore-retail.com',
	'glasses.com',
	'oliverpeoples.com',
	'apexbysunglasshut.com',
	'arnette.com',
	'nextoreretail.com',
	'vistasi.it',
	'usstandardissue.com',
	'Esseyepro.com',
	'targetoptical.com',
	'persol.com',
	'sghcares.com',
	'mikli.com',
	'br.oticascarol.com',
	'za.sunglasshut.com',
	'vogue-eyewear.com',
	'luxotticagroupNAM.onmicrosoft.com',
	'contactsdirect.com',
	'omatter.com',
	'osaretail.com',
	'hk.luxottica.com',
	'customercare.oliverpeoples.com',
	'customercare.vogue-eyewear.com',
	'customercare.persol.com',
	'cn.luxottica.com',
	'usoakleysales.com',
	'usluxsales.com',
	'na-sales.oakley.com',
	'na-sales.luxottica.com',
	'luxotticagroup.mail.onmicrosoft.com',
	'eyemedcontacts.com',
	'lenscrafters.com',
	'bg.luxottica.com',
	'el.luxottica.com',
	'mo.luxottica.com',
	'sg.sunglasshut.com',
	'sferoflex.com',
	'click-to-order.luxottica.net',
	'sghstore.com',
	'pearlevisioncontacts.ca',
	'myhumanavcp.com',
	'iloristyle.com',
	'ilori.com',
	'eyewearspecialoffers.com',
	'ecpa.com',
	'se.luxottica.com',
	'novidades-br.ray-ban.com',
	'moneikos.luxottica.com',
	'kl.it.luxottica.com',
	'it.luxottica.com',
	'fi.luxottica.com',
	'dk.sunglasshut.com',
	'be.sunglasshut.com',
	'ae.sunglasshut.com',
	'il.luxottica.com',
	'uk.sunglasshut.com',
	'tr.sunglasshut.com',
	'th.sunglasshut.com',
	'pt.sunglasshut.com',
	'nl.sunglasshut.com',
	'my.sunglasshut.com',
	'mx.sunglasshut.com',
	'it.sunglasshut.com',
	'fr.sunglasshut.com',
	'es.sunglasshut.com',
	'de.sunglasshut.com',
	'br.sunglasshut.com',
	'at.sunglasshut.com',
	'sk.luxottica.com',
	'si.luxottica.com',
	'sgh.luxottica.com',
	'sg.luxottica.com',
	'ru.luxottica.com',
	'ro.luxottica.com',
	'pt.luxottica.com',
	'press.luxottica.com',
	'pl.luxottica.com',
	'th.luxottica.com',
	'tr.luxottica.com',
	'za.luxottica.com',
	'pe.luxottica.com',
	'nordic.luxottica.com',
	'nl.luxottica.com',
	'my.luxottica.com',
	'kr.luxottica.com',
	'jp.luxottica.com',
	'in.luxottica.com',
	'ie.luxottica.com',
	'hu.luxottica.com',
	'hr.luxottica.com',
	'gr.luxottica.com',
	'es.luxottica.com',
	'ec.luxottica.com',
	'fr.luxottica.com',
	'uk.luxottica.com',
	'dk.luxottica.com',
	'de.luxottica.com',
	'cz.luxottica.com',
	'co.luxottica.com',
	'cl.luxottica.com',
	'ch.luxottica.com',
	'ca.luxottica.com',
	'be.luxottica.com',
	'atelier.luxottica.com',
	'at.luxottica.com',
	'ar.luxottica.com',
	'mx.luxottica.com',
	'ae.luxottica.com',
	'br.luxottica.com',
	'us.luxottica.com',
	'luxotticagroup.onmicrosoft.com',
	'wccctf.com',
	'costabrands.com',
	'costasunglasses.com',
	'nativeyewear.com',
	'RLBoptical.com',
	'oakleyvault.com',
	'costadelmar.com',
	'ch.sunglasshut.com',
	'mesvision.com',
	'essilorluxottica-group.com',
	'shop.oliverpeoples.com',
	'Shop.ray-ban.com',
	'leonardo.essilorluxottica.com',
	'shop.vogue-eyewear.com',
	'shop.persol.com',
	'Teamvisionteam.com',
	'shop.arnette.com',
	'essilorluxottica.com',
	'o365.essilorluxottica.com',
	'ext.essilorluxottica.com',
	'vendorportal.essilorluxottica.com',
	'o365.ext.essilorluxottica.com',
	'o365.vendorportal.essilorluxottica.com',
	'br.360.essilorluxottica.com',
	'o365.br.360.essilorluxottica.com',
	'accurxinc.com',
	'athloneoptical.ie',
	'balester.com',
	'bazell.com',
	'bbgritalia.it',
	'brillenglas.de',
	'bugaboos.com',
	'canoptec.ca',
	'cascadeoptical.ca',
	'clarityoptical.ca',
	'clubstock.ca',
	'crizal.ca',
	'crossbowsoptical.co.uk',
	'crossbowsoptical.com',
	'easternoptical.ca',
	'ecpplt.es',
	'ecpu.com',
	'ecpuniversity.com',
	'eloa.com',
	'bbgr.es',
	'delamare-sovra.fr',
	'essilor.at',
	'essilor.bg',
	'essilor.co.uk',
	'essilor.com.ph',
	'essilor.fi',
	'essilor.fr',
	'essilor.ie',
	'essilor.in',
	'essilor.ru',
	'essilor-caraibes.fr',
	'essiloressc.co.uk',
	'essilorgroup.com',
	'essilorksa.com',
	'essilorlao.la',
	'essilormu.com',
	'essilor-sunsolution.com',
	'fyoptical.com',
	'intercast.it',
	'isbiroptik.com',
	'ltcmorocco.com',
	'ltcturkey.com',
	'essilor.com.bd',
	'essilor.be',
	'essilor.dk',
	'antoinelaoun.com',
	'bbgr.se',
	'cfe-optique.com',
	'cityoptical.com.au',
	'essilor.co.ao',
	'essilor.co.nz',
	'essilor.com.hk',
	'essilor.cz',
	'essilor.ee',
	'essilor.hr',
	'essilor.ng',
	'essilor.no',
	'essilor.pl',
	'essilor.co.th',
	'essilor.vn',
	'essilorea.com',
	'essilor.com.kh',
	'essilorme.com',
	'essilorusa.com',
	'essilor.com.sg',
	'cr.luxottica.com',
	'ext.luxottica.com',
	'essilor.com',
	'cn.essilor.com',
	'essilor.es',
	'o365.essilor.com',
	'essilor.se',
	'essilor.ca',
	'ext.essilor.com',
	'essilor.com.br',
	'essilor.eu',
	'bbgr.fr',
	'essilor.it',
	'essilor.ch',
	'essilor.com.co',
	'sp.essilor.com',
	'transitions.com.ph',
	'kodaklens.co.uk',
	'galileoitalia.it',
	'etscs.ch',
	'essilorchina.com',
	'VisionDirect.co.uk',
	'crizal.com',
	'transitions.fr',
	'nassaulens.com',
	'glassesdirect.co.uk',
	'glassesdirect.com',
	'polycore.com',
	'eyezen.com.br',
	'partners.essilor.com',
	'osalens.com.au',
	'rh-lenses.com',
	'ext.transitions.com',
	'satislohusa.com',
	'nassauoogp.com',
	'ni.luxottica.com',
	'essilor.co.za',
	'4c.de',
	'sivogroup.com',
	'sunopticaltech.com',
	'essilor.co.id',
	'essilor.co.kr',
	'lenscare.de',
	'lensbest.de',
	'stylloptical.com.br',
	'prt.essilor.com',
	'apps.essilor.com',
	'getlenses.co.uk',
	'wlclens.co.uk',
	'essilorgroup.co.uk',
	'unitedoptical.co.uk',
	'psgeyewear.com.au',
	'sinclairoptical.co.uk',
	'sunglassesshop.com',
	'myoptiquegroup.com',
	'onlineindiagroup.com',
	'omega-optix.eu',
	'visiondirect.nl',
	'nvg-china.com',
	'movisia.ma',
	'visiondirect.es',
	'polinelli.it',
	'laboratoiresdl.ca',
	'companygrandvision.ru',
	'ocfyt.net',
	'kodaklens.de',
	'rxlaboratorios.com.br',
	'orgalente.com.br',
	'moa.com.ar',
	'ocutec.com.pe',
	'essilor.com.my',
	'gialens.com.hk',
	'sunixevue.com.au',
	'jzo.com.pl',
	'satisloh.com',
	'essilor.de',
	'jaikudo.pl',
	'luxotticaAU.onmicrosoft.com',
	'essilor.nl',
	'optodev.essilor.com.ph',
	'rh-brillenglas.de',
	'essilor.hu',
	'essilor.si',
	'vision.cr',
	'nika.de',
	'bbgr.eu',
	'opticworks.nl',
	'infield-safety.de',
	'essilor.pt',
	'essilor-omc.fr',
	'essilor-group.essilor.com',
	'newsletter.essilor.com',
	'ext.essilor.fr',
	'groups.essilor.com',
	'coupon.essilor.fr',
	'bbgr.co.za',
	'coastalvisionchina.com',
	'essilor.sk',
	'bbgr.dk',
	'essilor.rs',
	'eyezen.fr',
	'bbgr.com',
	'essilor.co.ke',
	'essilor.sa',
	'essilorpro.fr',
	'essiloracademy.eu',
	'polycore.com.hk',
	'essilor.id',
	'essilorsamples.com',
	'intercast-group.com',
	'hoisite.nl',
	'essilorinstrumentsusa.com',
	'essilor.com.ar',
	'essilor.com.tw',
	'ussafety.com',
	'my-essilor-rewards.com',
	'bbgr-optiben.com',
	'opakoptik.com',
	'essilorindia.com',
	'optic-club.ru',
	'bbgr.co.uk',
	'hocsite.com',
	'essilor.com.pl',
	'essilor.com.au',
	'essilorconecta.com.br',
	'deceunynck.be',
	'brille24.de',
	'megalux.cl',
	'transitions.co.th',
	'ext.essilor.com.br',
	'Transitions.ie',
	'lnoptic.com',
	'luis-optica.ru',
	'transitions.com',
	'laboptilab.com',
	'reize.ch',
	'issentialoptics.ie',
	'dactechnologies.com',
	'grandvision.ru',
	'unilab.ind.br',
	'optiexpress.com.do',
	'opticapopularhn.com',
	'optisa.cr',
	'essilor.ro',
	'primeoptical.com.br',
	'omega-optix.com',
	'opticalsupplysingapore.com.sg',
	'spherical.co.za',
	'osa.com.sg',
	'sunixvision.com.au',
	'infield-safety.com',
	'ext.essilorindia.com',
	'ext.ext.essilor.com',
	'repro.com.br',
	'elac.essilor.com',
	'tri-supreme.com',
	'omics.com',
	'labsturmer.com.br',
	'nikonlenswear.com',
	'jzo.com.ua',
	'grupaessilor.pl',
	'winoptical.com',
	'jzo.lt',
	'orgalent.com.br',
	'21stcenturyoptics.com',
	'essilor.sg',
	'indulentes.com.ec',
	'essilorsource.ca',
	'stereooptical.com',
	'psanilo.com.br',
	'oneclickventures.com',
	'eyebiz.co.th',
	'valoptec.com',
	'essilor-africa.com',
	'fgxi.com',
	'agenda.essilorluxotticadays.essilorluxottica.com',
	'tools.essilorluxotticadays.essilorluxottica.com',
	'nationaloptronics.com',
	'omax.nl',
	'miraflex.com.co',
	'surfriachuelo.com.br',
	'bookingessilorluxotticadays.essilorluxottica.com',
	'farolrs.com.br',
	'optogenics.com',
	'mont-royal.fr',
	'visolab.com.br',
	'ltllenses.it',
	'salesforce.essilor.com.br',
	'ilt.essilor.com.br',
	'email.fgxi.com',
	'asia.satisloh.com',
	'consumables.satisloh.com',
	'emea.satisloh.com',
	'latam.satisloh.com',
	'newsletter.satisloh.com',
	'precision.satisloh.com',
	'slna.satisloh.com',
	'ryderseyewear.com',
	'cn1.luxottica.com',
	'hk1.luxottica.com',
	'mo1.luxottica.com',
	'variluxpresbyopiasummit.com',
	'optiminas.com.br',
	'r.transitions.com',
	'stylemark.net',
	'cromalentes.com.br',
	'servioptica.essilor.com.br',
	'farol.essilor.com.br',
	'netzoptiker.de',
	'iltoptics.com',
	'opsm.au',
	'dacvision.com',
	'toravision.com',
	'budgeteyewear.com.au',
	'lens2lens.de',
	'essilorlanka.com',
	'trisupreme.com',
	'lentesgbo.com.br',
	'fgxe.co.uk',
	'dioptics.com',
	'pro-optic.ca',
	'kwoptical.com',
	'westlab.ca',
	'nz.oakley.com',
	'oakley.com.au',
	'tecoptik.com.au',
	'laubmanandpank.com.au',
	'luxottica.com.au',
	'au.luxottica.com',
	'mylocaloptometrists.com.au',
	'mylocaloptometrists.co.nz',
	'opsm.co.nz',
	'opsm.com.au',
	'rayban.com.au',
	'nz.luxottica.com',
	'sunglasshut.co.nz',
	'luxottica.co.nz',
	'sunglasshut.com.au',
	'onesight.org.au',
	'visionware.ca',
	'essilorexperts.com',
	'shorelens.com',
	'ipekoptik.com',
	'tecnolens.com.br',
	'nz.sunglasshut.com.au',
	'bnl-eurolens.com',
	'visiondirect.info',
	'montroyal-ophtalmique.com',
	'grownoptical.com.br',
	'tshoptical.com',
	'Optuitive.com',
	'variluxespecialista.com.br',
	'institutovereviver.org.br',
	'Opticlick.com.br',
	'essilornews.com.br',
	'topcon-essilor.co.jp',
	'elcorp.fr',
	'riversideoptical.ca',
	'signetcolombia.com',
	'Transitions.com.br',
	'lentillasadomicilio.com',
	'mcleodoptical.com',
	'arcoating.com.ar',
	'precision-optical-co.com',
	'tgt-tw.com',
	'omega-optix.sk',
	'transitionscampus.com',
	'transitions.co.nz',
	'flashframes.com',
	'visionstyle.ca',
	'rozinoptical.com',
	'visiondirect.it',
	'perferxprecision.com',
	'essilorpromo.com.br',
	'visionstarllc.com',
	'glasses4you.com.br',
	'comprol.com.br',
	'iltlatam.com',
	'optovision.com.ar',
	'nikon-lenswear.pl',
	'nikonlenswear.it',
	'nikon-lenswear.eu',
	'nikon-lenswear.es',
	'nikonlenswear.com.br',
	'nikonlenswear.com.au',
	'nikon-lenswear.ch',
	'nikoneyes.com.au',
	'nikoneyes.com',
	'nikonexpert.it',
	'ghanadaoptical.com',
	'sentralslip.no',
	'horizonoptical.co.uk',
	'ndulentes.com.ec',
	'4care.de',
	'essilorbrasil.com.br',
	'essilux.com',
	'getoptics.com',
	'xperio.ca',
	'partnershiprewardsportal.com',
	'optiquesolidaire.fr',
	'kodaklens.com.co',
	'essilor.ph',
	'kaleidovision.com.sg',
	'visionimpactinstitute.org',
	'myframeboard.com',
	'optolab.com.co',
	'essilab.com.ph',
	'getlenses.com',
	'labtechnopark.com.br',
	'servioptica.co',
	'advanceoptical.com',
	'dacoptic.com',
	'optifacts.com',
	'nexooptics.com',
	'perferxoptical.com',
	'suntechoptics.com',
	'fabrislane.co.uk',
	'opticapopularhn.net',
	'omega-optix.cz',
	'luxotticaretail.net',
	'issilor.co.kr',
	'essilorgroup.org',
	'eyebiz.com.au',
	'elens.com.br',
	'cristalyplastico.com.mx',
	'oakleystore.com',
	'luxgroup.net',
	'medicaleyeservices.com',
	'perlvision.com',
	'searsopticalcontacts.com',
	'revo.com',
	'oakley.com.sg',
	'mikli.it',
	'mikli.fr',
	'luxotticaretail.com.cn',
	'luxottica.it',
	'luxottica.com.hk',
	'luxosp.com',
	'givethegiftofsight.org',
	'essilor.local',
	'essilor.com.mx',
	'coolwinks.com',
	'oogp.com',
	'surfriachuelo.com',
	'nikonoptical.co.uk',
	'scl-intl.com',
	'agi-international.com.sg',
	'topconvisioncare.co.jp',
	'amexoptics.com',
	'omipro.fr',
	'reliableoptics.com',
	'quantumoptique.com',
	'4care.biz',
	'aaifgg.com',
	'grupogbo.com.br',
	'labtechnopark.essilor.com.br',
	'nikon-lenswear.de',
	'lentiexpress.es',
	'optolab.co',
	'acumed.de',
	'osme-ke.com',
	'visiondirect.be',
	'contactlensgroup.co.uk',
	'emnuk.co.uk',
	'rxsg.com',
	'visiondirect.ie',
	'elo.ru',
	'getoptics.org',
	'institutosuperiorocular.com',
	'osph.com.ph',
	'corinnemccormack.com',
	'essilorvisionfoundation.org.nz',
	'galileoitaliait.it',
	'onlineoptical.com',
	'transitions.com.au',
	'trendoptical.com.tw',
	'visiondirect.fr',
	'wallaceeverett.com.au',
	'kraftman.com',
	'nikon-essilor.co.jp',
	'nikonlenswear.co.uk',
	'spectrumopticalinc.com',
	'costadelmar.es',
	'essilorvisionfoundation.com.au',
	'eyezen.es',
	'fostergrant.com',
	'lens4eyes.de',
	'openotica.com.br',
	'psgeyewear.co.nz',
	'psgeyewear.nz',
	'rroptical.com',
	'signetasia.com.sg',
	'Transitions.com.sg',
	'visiondirectusa.com',
	'advancedoptical.com.au',
	'allindiagroups.com',
	'amera.sg',
	'bulgaria.bg',
	'distrilens.fr',
	'essilor.au',
	'essilorconnectedglasses.com',
	'essilorconteudo.com.br',
	'essilornetwork.ca',
	'getlenses.ie',
	'lens2lens.at',
	'lenscare.ch',
	'meyestore.ca',
	'meyestore.co.uk',
	'netview.store',
	'nikon-lenswear.com.au',
	'nvg-global.com',
	'optistar.de',
	'oselens.fr',
	'postoptics.co.uk',
	'postoptics.com',
	'precisionoptics.com.au',
	'primeoptica.com.br',
	'proessilor.com.br',
	'rio de mouro.pt',
	'rxsg.com.au',
	'signetarmorlite.com',
	'signetiberica.com',
	'slovenia.si',
	'Stylescience.com',
	'tol3.transitions.ie',
	'.co.id',
	'accelerate.coach',
	'accountsreceivable',
	'amera.ae',
	'bolon-eyewear.com.au',
	'contactlensgroup.com',
	'eiusasupport.com',
	'esilorindia.com',
	'esol.co.in',
	'essilo.com.br',
	'essilor.com.ng',
	'essilor.com.za',
	'essilor-caribes.fr',
	'essilorfacturas.com.mx',
	'essilorgrup.co.uk',
	'essilrousa.com',
	'esslor.fr',
	'esssilor.ca',
	'eyes-and-sun.com',
	'fabris.lane.co.uk',
	'fgxi.coom',
	'galileo.it',
	'gandvision.ru',
	'garantiacrizal.com.br',
	'getoptics.co.uk',
	'gmail.com.br',
	'iltoptics.com.my',
	'info.satisloh.com',
	'institutovereviver.com.br',
	'labsdl.com',
	'lensbest.at',
	'lensbest.ch',
	'lenscare.at',
	'lisbon.pt',
	'luis-optika.ru',
	'Luxottica.co.uk',
	'mail.diopticssunwear.com',
	'mes360app.com',
	'meyestore.fr',
	'myonlineoptical.de',
	'myonlineoptical.fr',
	'MyVariluxSSeries.com',
	'nakedeyeenterprises.com',
	'new.polycore.com.hk',
	'nexoooptics.com',
	'nikon.es',
	'nikonlenswear.eu',
	'nikon-lenswear.nl',
	'nikon-opt.com',
	'opakoptikcom',
	'opticaleducation.com',
	'opticclub.ru',
	'optifog.com',
	'optiquecristal.com',
	'opv.cl',
	'o-r.co.uk',
	'osa.au',
	'osdindia.com',
	'oticacidada.com.br',
	'oticacidada.org.br',
	'polinell.it',
	'promocaoenxergueivantagem.com.br',
	'psgeyewear.com',
	'reseauessilor.ca',
	'rxclub.ca',
	'stockclub.ca',
	'tgt-tw.comj',
	'Thornbury.co.uk',
	'Transitions.com.fr',
	'transitions.ph',
	'variluxemdobro.com.br',
	'varilux-university.org',
	'visaocidada.org.br',
	'visiondirect.pt',
	'visionforlifefund.org',
	'visionoptique.ca',
	'westclub.ca',
	'ageisjustanumber.com',
	'essilorinstrumentssua.com',
	'essishop.fr',
	'eyezen-challenge.com',
	'gotooptician.com',
	'labonnevue.fr',
	'logeyes.com.br',
	'meyestore.be',
	'meyestore.com',
	'meyestore.de',
	'meyestore.es',
	'meyestore.ie',
	'meyestore.it',
	'meyestore.nl',
	'meyestore.pt',
	'meyestore.us',
	'sharing-optics-marketing.com',
	'verresoptiquesnikon.fr',
	'essilor.cl',
	'essilor-omcs.fr',
	'bbgr.fr '
];

const loyalty_domain_blacklist_set = new Set();

// Iterate through the list of strings
for (const temp_domain of loyalty_domain_blacklist) {
    // Convert the current string to lowercase
    const lowercaseDomain = temp_domain.toLowerCase();

    // Add the lowercase string to the set
    loyalty_domain_blacklist_set.add(lowercaseDomain);
  }


/**
 * @param  {string} email - the email to be checked
 * @returns {boolean} true if the email is blacklisted for loyalty subscription, false otherwise
 */
function isInLoyaltyBlacklist(email: string): boolean {
	// Extract the domain part of the email address
	const domain = email.substring(email.indexOf('@') + 1).toLowerCase();

	// Check if the domain is in the set 
	const isInBlacklist = loyalty_domain_blacklist_set.has(domain)

	return isInBlacklist;
}

export { isInLoyaltyBlacklist }
