import React from "react";
import styled from "styled-components";
import {
  NativeSelect,
  NativeSelectProps as MuiNativeSelectProps,
} from "@mui/material";
import type { Option } from "../types/option";

type NativeSelectProps = {
  options: Option[];
};

type StyledNativeSelectProps = {
  m?: React.CSSProperties["margin"];
};

const StyledNativeSelect = styled(NativeSelect)<StyledNativeSelectProps>`
  select {
    border-radius: 5px;
    font-family: inherit;
    color: inherit;
    font-size: 12px;
    width: 100%;
    height: 75%;
    bottom: 5px;
    position: relative;
    margin: ${({ m }) => (m ? m : "0")};
  }
`;

const Select: React.FC<
  NativeSelectProps & StyledNativeSelectProps & MuiNativeSelectProps
> = (props) => {
  const { options } = props;

  return (
    <StyledNativeSelect {...props}>
      <option value="">-</option>
      {options.map((option) => (
        <option value={option.value}>{option.label}</option>
      ))}
    </StyledNativeSelect>
  );
};

export default Select;
