import { loadScript, appendDiv } from "./script";

function loadGoogleMapApi() {
    if (typeof window !== "undefined") {
        if (!document.querySelector("#google-maps")) {  
            /*  
            loadScript(
                "https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0&features=es7%2Cdefault%2Ces6%2Ces5%2Ces2023%2Ces2022%2Ces2020%2Ces2021%2Ces2019%2Ces2018%2Ces2017%2Ces2016%2Ces2015%2Cdom4",
                document.querySelector("head"),
                "cloudflare-polyfill"
            ); 
            */       
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${process.env.GMAPS_API_KEY}&region=US&libraries=places&v=quarterly`,
                document.querySelector("head"),
                "google-maps"
            );
            try {
                // Test autocompletion module load
                eval('let y = BigInt(1234567890123456789012345)');

                // Track if browser is compatible
                if (!document.querySelector("#gmaps_apis_compatible")){
                    const div_elem = document.createElement('div');
                    appendDiv(
                        document.querySelector("body"),
                        "gmaps_apis_compatible"
                    );
                }
            } catch (e) {
                console.error(`Browser does not support Google Maps API`)
            }
        }
    }
}

export { loadGoogleMapApi };