import {
  AppBar,
  Dialog,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

interface Props {
  iframeURL: string;
  isOpen: boolean;
  handleClose: () => void;
}

export default function ModalIframe({ iframeURL, isOpen, handleClose }: Props) {
  return (
    <Dialog open={isOpen} onClose={handleClose} fullScreen={true}>
      <AppBar sx={{ position: "relative" }}>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          style={{ marginLeft: "auto" }}
        >
          <CloseIcon />
        </IconButton>
      </AppBar>
      <iframe src={iframeURL} width="100%" height="100%" title="iframe" />
    </Dialog>
  );
}
