import { MouseEvent, useContext, useEffect, useState } from "react";

import { Context } from "../App";
import ModalIframe from "./ModalIframe";
import parse from "html-react-parser";

interface Props {
  children: string;
}

export default function ContentWithLinks({ children }: Props) {
  const { storeMapData, storeIsdcData } = useContext(Context);
  const [parsedContent, setParsedContent] = useState<string>("");
  const [modalDialogOpen, setModalDialogOpen] = useState<boolean>(false);
  const [iframeURL, setIframeURL] = useState<string>("");

  const modalDialogHandleClose = () => {
    setModalDialogOpen(false);
  };

  const onClickLink = (evt: any) => {
    evt.preventDefault();
    setIframeURL(evt.target.href);
    setModalDialogOpen(true);
  };

  useEffect(() => {
    if (storeIsdcData.country) {
      const countryCode =
        storeIsdcData.country === "CA" && storeIsdcData.isdc__language === "fr"
          ? "CA-FR"
          : storeIsdcData.country;
      setParsedContent(
        children
          .replace(
            /\[PrivacyPolicyLink\]/g,
            "<a onClick={onClickLink} href='" +
              storeMapData.externalLinks.privacyPolicy[countryCode] +
              "'>"
          )
          .replace(
            /\[Disclaimer1\]/g,
            `<a onClick={onClickLink} href=${`${window.location.origin}/static/docs/1_yurtdışı_aktarım_açık_rıza_metni_220916.pdf`}>`
          )
          .replace(
            /\[Disclaimer2\]/g,
            `<a onClick={onClickLink} href=${`${window.location.origin}/static/docs/2_pazarlama_açık_rıza_metni_220916.pdf`}>`
          )
          .replace(
            /\[Disclaimer3\]/g,
            `<a onClick={onClickLink} href=${`${window.location.origin}/static/docs/3_aydınlatma_metni_220916.pdf`}>`
          )
          .replace(/\[\/\]/g, "</a>")
          .replace(
            /\[TermsAndConditionsLink\]/g,
            "<a onClick={onClickLink} href='" +
              storeMapData.externalLinks.termsAndConditions[countryCode] +
              "'>"
          )
          .replace(
            /\[CaliforniaNFILink\]/g,
            "<a onClick={onClickLink} href='" +
              storeMapData.externalLinks.californiaNFI[countryCode] +
              "'>"
          )
          .replace(
            /\[SpecialPromoLinkPersol\]/g,
            `<a onClick={onClickLink} href=${`${window.location.origin}/static/docs/PERSOL_SWEEPSTAKES_OFFICIAL_REGULATION_230524.pdf`}>`
          )
          .replace(
            /\[SpecialPromoLinkSGH\]/g,
            `<a onClick={onClickLink} href=${`${window.location.origin}/static/docs/SGH_SWEEPSTAKES_OFFICIAL_REGULATION_231003.pdf`}>`
          ).replace(
            /\[LoyaltyLinkSGH\]/g,
            "<a onClick={onClickLink} href='" +
              storeMapData.externalLinks.termsAndConditions[countryCode] +
              "'>"
          ).replace(
            /\[LoyaltyNFILinkSGH\]/g,
            "<a onClick={onClickLink} href='https://www.sunglasshut.com/us/sunglasses/privacy-policy#cm-910694'>"
          )
      );
    }
  }, [
    children,
    storeIsdcData.country,
    storeIsdcData.isdc__language,
    storeMapData.externalLinks.privacyPolicy,
    storeMapData.externalLinks.termsAndConditions,
  ]);

  return (
    <>
      {parse(parsedContent, {
        replace: (domNode: any) => {
          if (domNode.attribs?.onclick === "{onClickLink}") {
            domNode.attribs.onClick = onClickLink;
            delete domNode.attribs.onclick;
            return domNode;
          }
        },
      })}
      <ModalIframe
        iframeURL={iframeURL}
        isOpen={modalDialogOpen}
        handleClose={modalDialogHandleClose}
      />
    </>
  );
}
