import TRANSLATIONS_DE from "./locales/de.json";
import TRANSLATIONS_EN from "./locales/en.json";
import TRANSLATIONS_ES from "./locales/es.json";
import TRANSLATIONS_FR from "./locales/fr.json";
import TRANSLATIONS_NL from "./locales/nl.json";
import TRANSLATIONS_PT from "./locales/pt.json";
import TRANSLATIONS_TR from "./locales/tr.json";
import TRANSLATIONS_IT from "./locales/it.json";
import TRANSLATIONS_AU from "./locales/au.json";
import TRANSLATIONS_SG from "./locales/sg.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
  },
  debug: true,
  resources: {
    en: {
      translation: TRANSLATIONS_EN,
    },
    es: {
      translation: TRANSLATIONS_ES,
    },
    de: {
      translation: TRANSLATIONS_DE,
    },
    fr: {
      translation: TRANSLATIONS_FR,
    },
    nl: {
      translation: TRANSLATIONS_NL,
    },
    pt: {
      translation: TRANSLATIONS_PT,
    },
    tr: {
      translation: TRANSLATIONS_TR,
    },
    it: {
      translation: TRANSLATIONS_IT,
    },
    au: {
      translation: TRANSLATIONS_AU,
    },
    sg: {
      translation: TRANSLATIONS_SG,
    },
  },
});

export default i18n;
